import React from "react"

export const PageContext = React.createContext(null)

export const PageContextProvider = ({
  pageContext,
  location,
  children,
}: any) => {
  return (
    <PageContext.Provider
      value={
        {
          location,
          pageContext,
        } as any
      }
    >
      {children}
    </PageContext.Provider>
  )
}
export default PageContext
