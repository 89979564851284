import React from "react"
import ReactDOM from "react-dom/client"
import { PageContextProvider } from "./src/providers/page_context"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Toaster } from "react-hot-toast"
import "./src/styles/global.scss"

// This is key to fix the Hydration errors !!!!
// https://stackoverflow.com/questions/77651939/getting-uncaught-error-minified-react-error-418-on-my-gatsby-production-build
export const replaceHydrateFunction = () => {
  return (element, container) => {
    ReactDOM.createRoot(container).render(element)
  }
}

export const wrapPageElement = ({ element, props }) => (
  <main>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_V3_SITE_KEY}>
      <PageContextProvider {...props}>{element}</PageContextProvider>
      <Toaster />
    </GoogleReCaptchaProvider>
  </main>
)
